


import MoviePlayerControlArea from '@/components/lib/MoviePlayerControlArea/index.vue';
import MovieMetaInfoArea from '@/components/lib/MovieMetaInfoArea/index.vue';
import MapElemInfoNew from '@/components/Top/mapElemInfoComponents/MapElemInfoNew.vue';
import MoviePlayerVideo from '@/components/lib/MoviePlayer/MoviePlayerVideo.vue';
import ThreeAxisSensorChart from '@/components/lib/ThreeAxisSensorChart/index.vue';
import { getGeoItemMeta, syncGeoItemWithParent } from '@/lib/geoItemHelper';
import {
  computed,
  defineComponent,
  getCurrentInstance,
  nextTick,
  onMounted,
  onUnmounted,
  PropType,
  reactive,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import ExtremeMap from '@/components/lib/ExtremeMap/index.vue';
import { logMoviePlay, logMoviePlaySeconds } from '@/lib/analyticsHelper';
import {
  GeoItemMeta,
  GeoItemsMeta,
  GIMovie,
  GIMovieGeoIndex,
  GIMovieList,
} from '@/models/geoItem';
import {
  getMovieFileUrlObj,
  MovieFileUrlObj,
  searchContinuedMovies,
  preCalculateMovieVals,
  getSensorDataOfMovieList,
  openNewInfraDoctorTab,
  updateMovieTags as updateMovieTagsHelper,
  getCurrentLocationDisplayInfo,
  isTimeDispChanged,
  appendFirstMgisFromContinuedMovieToLastMovie,
} from '@/lib/moviePlayerHelper';
import {
  ExtremeMapEssentials,
  MapElemInfo,
  Location,
  MovieTagModalParams,
} from '@/models';
import {
  SeekParams,
  AnalyticsParams,
  MoviePlayerParams,
  VideoControlProperties,
} from '@/models/moviePlayer';
import { MovieTag } from '@/models/apis/movie/movieResponse';
import { RoadName } from '@/models/apis/master/masterResponse';
import { CustomGeoItemLayer } from '@/models/apis/user/userResponse';
import EMPinLayerManager from '@/lib/extreme_map/EMPinLayerManager';
import { TextObj } from '@/lib/videojsHelper';
import { dtFormat } from '@/lib/dateHelper';
import { Comment } from 'src/models/apis/comment/commentResponse';
import { SensorData } from '@/models/apis/mtx/mtxResponse';

interface StyleState {
  videoHeight: string;
  infoHeight: string;
  lidarWidth: string;
}

interface MapElemInfoData {
  lat: string;
  lon: string;
  kp_uid: string | null;
}

interface MapElemInfoState extends MapElemInfo<MapElemInfoData> {
  candidateImages: (Blob | string)[];
}

interface MoviePlayerState {
  playerType: 'full';
  isInitialized: boolean;
  viewMode: 'full' | 'comp' | 'lidar';
  currentMovieLists: GIMovieList[];
  currentMovieListId: string;
  currentMovieList: GIMovieList | null;
  startTimeDisp: string;
  endTimeDisp: string;
  currentMovie: GIMovie | null;
  currentMovieIdx: number;
  movieFileUrlObj: MovieFileUrlObj;
  isFirstMovie: boolean;
  isLastMovie: boolean;
  isCurrentMovieReady: boolean;
  currentMgi: GIMovieGeoIndex | null;
  currentMgiIdx: number;
  locationDisp: string;
  currentMovieCurrentDt: Date | null;
  continuedMovies: GIMovie[];
  isMovieEnded: boolean;
  defaultPlaySpeed: number;
  videoControlProperties: VideoControlProperties;
  currentTime: number;
  titleBarTexts: TextObj;
  vjsLoadedData: Record<string, boolean>;
  vjsPrepared: Record<string, boolean>;
  vjsError: string[];
  currentTimeLidar: number;
  lidarMovieOffsetMsec: number;
  lidarMovieOffsetMsecMin: number;
  lidarMovieOffsetMsecMax: number;
  currentLidarMovieCurrentDt: Date | null;
  showMovieListSelect: boolean;
  seek: SeekParams;
  isSeeking: boolean;
  isTryingToPlayAnotherMovie: boolean;
  mapPinId: string;
  geoItemsMeta: GeoItemsMeta;
  movieTagModal: MovieTagModalParams;
  styles: StyleState;
  mapElemInfo: MapElemInfoState | null;
  analytics: AnalyticsParams;
  isDownloadingMovieFile: boolean;
  isDownloadingScreenshot: boolean;
  showSensorChart: boolean;
  createMapElemFailed: number;
}

export default defineComponent({
  name: 'movie-player-Lidar',
  props: {
    roadNameDispMap: {
      type: Object as PropType<Record<string, RoadName>>,
      default: () => { return {}; },
    },
    movieLists: {
      type: Array as PropType<Array<GIMovieList>>,
      default: () => { return []; },
    },
    movieListId: {
      type: String,
      default: '',
    },
    extremeMapEssentials: {
      type: Object as PropType<ExtremeMapEssentials>,
      default: () => { return null; },
    },
    parentGeoItemsMeta: {
      type: Object as PropType<GeoItemsMeta>,
      default: () => { return {}; },
    },
  },
  setup(props, { emit }) {
    const initStyleState = (): StyleState => {
      return {
        videoHeight: '48%',
        // lower-areaのHeight、BasicMoviePlayerと共通のmixinを使っているため、この名称のままとする。
        infoHeight: '51%',
        lidarWidth: '16%',
      };
    };
    const initMovieFileUrlObjState = (): MovieFileUrlObj => {
      return {
        left: '',
        right: '',
        front: '',
        lidar1: '',
      };
    };
    const initMovieTagModalState = (): MovieTagModalParams => {
      return {
        show: false,
        tags: [],
        movie: null,
      };
    };
    const initVideoControlPropertiesState = (): VideoControlProperties => {
      return {
        playSpeed: 1.0,
        volume: 0,
        // playingだけでは、falseのままでは再実行されないため、
        // 元の動きと異なりますので、playとpause両方でコントロールする。
        play: 0,
        pause: 0,
        tickMsec: 0,
        reset: 0,
        loadMovie: 0,
        isPlayBackward: false,
      };
    };
    const initSeekState = (): SeekParams => {
      return {
        current: 0,
        min: 0,
        max: 100,
        step: 1,
      };
    };
    const initAnalyticsState = (): AnalyticsParams => {
      return {
        accumulatedPlayMsecs: 0,
        tmpCalcBase: -1,
        playMsecSendThres: 5000,
      };
    };
    const state = reactive<MoviePlayerState>({
      playerType: 'full',
      isInitialized: false,
      viewMode: 'lidar',
      currentMovieLists: [],
      currentMovieListId: '',
      currentMovieList: null,
      startTimeDisp: '00:00:00',
      endTimeDisp: '00:00:00',
      currentMovie: null,
      currentMovieIdx: -1,
      movieFileUrlObj: initMovieFileUrlObjState(),
      isFirstMovie: false,
      isLastMovie: false,
      isCurrentMovieReady: false,
      currentMgi: null,
      currentMgiIdx: -1,
      locationDisp: '',
      currentMovieCurrentDt: null,
      continuedMovies: [],
      isMovieEnded: false,
      defaultPlaySpeed: 1.0,
      videoControlProperties: initVideoControlPropertiesState(),
      currentTime: 0,
      titleBarTexts: {} as TextObj,
      vjsLoadedData: {},
      vjsPrepared: {},
      vjsError: [],
      currentTimeLidar: 0,
      // 3カメ動画の下端がLIDARカーペットロールの下端に大体合うようにしたい.
      // LIDAR動画は車両前輪あたりのルーフから後ろ向きに撮影、カーペットは車両後方
      // 15(上端)から35(下端)mの範囲を撮影しているらしい. 3カメ位置とLIDARカメの横方向位置は大体同じとすると、
      // * 3カメ動画の下端はカメラ +5m
      // * LIDARカメラ位置からLIDARカーペット上端まで = 15m
      // * LIDARカーペット上端から下端まで = 20m
      // 都合40m分補正することになる. 70kmで走行する場合秒間大体20m程度進むので、2.0秒程度ずらせば
      // 大体映る感じになりそう. (実際はなぜだかそうなってないけど、まぁいいか)
      lidarMovieOffsetMsec: 2000,
      lidarMovieOffsetMsecMin: -15000,
      lidarMovieOffsetMsecMax: 15000,
      currentLidarMovieCurrentDt: null,
      showMovieListSelect: false,
      seek: initSeekState(),
      isSeeking: false,
      isTryingToPlayAnotherMovie: false,
      mapPinId: 'map-pin1',
      geoItemsMeta: {} as GeoItemsMeta,
      movieTagModal: initMovieTagModalState(),
      styles: initStyleState(),
      mapElemInfo: null,
      analytics: initAnalyticsState(),
      isDownloadingMovieFile: false,
      isDownloadingScreenshot: false,
      showSensorChart: false,
      createMapElemFailed: 0,
    });

    const refExtremeMap = ref<InstanceType<typeof ExtremeMap>>();
    const refTopBarArea = ref<HTMLDivElement>();
    const refVideoArea = ref<HTMLDivElement>();
    const refControlArea = ref<InstanceType<typeof MoviePlayerControlArea>>();
    const refMoviePlayerVideos123 = ref<InstanceType<typeof MoviePlayerVideo>[]>();
    const refMoviePlayerVideoLidar1 = ref<InstanceType<typeof MoviePlayerVideo>>();
    const refMetaInfoArea = ref<InstanceType<typeof MovieMetaInfoArea>>();
    const refSensorChart = ref<InstanceType<typeof ThreeAxisSensorChart>>();

    onMounted(() => {
      window.addEventListener('resize', () => onResize());
      initializeDataManagers();
      state.vjsPrepared = {
        [vid1.value]: false,
        [vid2.value]: false,
        [vid3.value]: false,
        [vidLidar1.value]: false,
      };
    });
    onUnmounted(() => {
      window.removeEventListener('resize', () => onResize());
    });

    // watch:
    watch(() => props.movieLists, () => {
      state.currentMovieLists = props.movieLists.map(ml => {
        ml = Object.assign({}, ml);
        ml.isSelected = true; // 赤枠
        ml.showTimeTexts = true; // 時間表示
        return ml;
      });
    });
    watch(() => props.movieListId, () => {
      if (!props.movieListId) { return; }
      state.currentMovieListId = props.movieListId;
      onCurrentMovieListIdChange();
    });
    watch(() => state.currentMovieCurrentDt, (newTimestamp, oldTimestamp) => {
      // 再生速度が変更できることを考慮し、時刻表示が変更されたタイミングで場所の表示を更新する
      if (!isTimeDispChanged(newTimestamp, oldTimestamp)) return;
      const currentMovieMgis = state.currentMovie?.movie_geo_indices;
      if (!state.currentMgi || !state.currentMovieCurrentDt || !currentMovieMgis) {
        // mgiが取れない場合は地図上のピンの位置の更新はしない
        state.locationDisp = '';
        return;
      }
      const mgis = [ ...currentMovieMgis ];
      const nextMovieIdx = state.currentMovieIdx + 1;
      const nextMovie = state.currentMovieList?.movies[nextMovieIdx];
      if (nextMovie) {
        mgis.push(...nextMovie.movie_geo_indices);
      }
      const locationDisplayInfo = getCurrentLocationDisplayInfo({
        currentTimestamp: state.currentMovieCurrentDt,
        movieGeoIndices: mgis,
        currentMgi: state.currentMgi,
        currentMgiIndex: state.currentMgiIdx,
      });
      state.locationDisp = locationDisplayInfo.movieMetaInfoLocationDisp;
      updateMapPinPosition({
        lat: locationDisplayInfo.location.lat.toString(),
        lon: locationDisplayInfo.location.lon.toString(),
      });
    });

    // computed
    const uid = getCurrentInstance()?.uid;
    const store = useStore();
    const ifdLink = computed(() => store.state.user.settings.ifd_link);
    const vid1 = computed(() => {
      return `vid1-${uid}`;
    });
    const vid2 = computed(() => {
      return `vid2-${uid}`;
    });
    const vid3 = computed(() => {
      return `vid3-${uid}`;
    });
    const vidLidar1 = computed(() => {
      return `vidLidar1-${uid}`;
    });
    const videoAspectRatio = computed(() => {
      return 16 / 9;
    });
    const lidarVideoAspectRatio = computed(() => {
      return 27 / 80;
    });
    const lidarMovieOffsetMsecDisp = computed(() => {
      // マイナスは勝手につく
      const sign = state.lidarMovieOffsetMsec >= 0 ? '+' : '';
      const tmpNum = Math.floor(state.lidarMovieOffsetMsec / 100) * 100;
      let offsetDisp = (tmpNum / 1000).toString();
      if (tmpNum % 1000 === 0) {
        offsetDisp += '.0';
      }
      return `${sign}${offsetDisp}s`;
    });
    const currentLidarMovieCurrentDtDisp = computed(() => {
      return dtFormat(state.currentLidarMovieCurrentDt, 'MM:SS');
    });
    const isCurrentMovieListReady = computed(() => {
      return !!state.currentMovieListId && state.currentMovieList;
    });
    const playerControlCurrentTimeDisp = computed(() => {
      let ret = '00:00:00';
      if (!state.currentMovieList) { return ret; }
      if (!isCurrentMovieListSearchTypeRoute.value) {
        // ルート検索以外の場合は撮影時刻を表示
        ret = dtFormat(state.currentMovieCurrentDt, 'HH:MM:SS');
      } else {
        // ルート検索の場合は現在の経過秒数を時刻表示に直したものを表示
        let sec = state.seek.current / 1000;
        const h = parseInt((sec / 3600).toString());
        sec = parseInt((sec % 3600).toString());
        const m = parseInt((sec / 60).toString());
        const s = parseInt((sec % 60).toString());
        ret = [
          ('0' + h).slice(-2),
          ('0' + m).slice(-2),
          ('0' + s).slice(-2),
        ].join(':');
      }
      return ret;
    });
    const isCurrentMovieListSearchTypeRoute = computed(() => {
      return state.currentMovieList && state.currentMovieList.searchType === 'route';
    });
    const compareModeTooltip = computed(() => {
      let ret = '';
      if (isCurrentMovieListSearchTypeRoute.value) {
        ret = 'ルート検索の場合、比較モードは利用できません';
      }
      return ret;
    });
    const visibleGeoItemLayers = computed(() => {
      return [props.parentGeoItemsMeta.map['comment']];
    });
    const hasContinuedMovies = computed(() => {
      return state.isLastMovie && state.continuedMovies.length > 0;
    });

    // methods
    const initializeDataManagers = () => {
      const layerListDefault: CustomGeoItemLayer[] = [
        { name: 'pin', dispName: 'pin' },
        { name: 'comment', dispName: '付箋' },
      ];
      state.geoItemsMeta.map = getGeoItemMeta(layerListDefault);
    };
    const findFirstSeekPoint = (): number => {
      if (!state.currentMovieList) {
        return 0;
      }
      // start_offsetが指定された場合はそこから再生する
      if (state.currentMovieList.startOffset !== null && state.currentMovieList?.startOffset !== undefined) {
        return state.currentMovieList.startOffset;
      }

      // 一番近かったmgiを探す
      let targetMgiId = null;
      const minDistance = state.currentMovieList.min_distance;
      // 範囲検索以外の場合は99999になっているはず
      if (minDistance > 1000) { return 0; }

      // 一番近かったmgiを探す
      for (const [mgiId, distance] of Object.entries(state.currentMovieList.hit_mgi_map)) {
        if (Math.abs(minDistance - parseInt(distance.toString())) < Number.EPSILON) {
          targetMgiId = mgiId.toString();
          break;
        }
      }
      if (!targetMgiId) { return 0; }
      // そこから再生
      for (const movie of state.currentMovieList.movies) {
        for (const mgi of movie.movie_geo_indices) {
          if (mgi.id.toString() === targetMgiId) {
            return (movie.accumMsec || 0) + (mgi.startMsecDiff || 0);
          }
        }
      }
      return 0;
    };
    const onResize = () => {
      if (!isCurrentMovieListReady.value) { return; }
      const windowW = window.innerWidth;
      const windowH = window.innerHeight;
      // モーダルのmarginおよびpadding, video間のmargin
      const wMargins = (8 * 2 + 8 * 2) + (2 * 6);
      const hMargins = 6 * 2 + 8 * 2;
      const videoH = (windowW - wMargins) / 3 / videoAspectRatio.value;
      state.styles.videoHeight = parseInt(videoH.toString()) + 'px';
      nextTick(() => {
        if (!refTopBarArea.value || !refVideoArea.value) { return; }
        const topBarH = refTopBarArea.value.clientHeight;
        const videoAreaH = refVideoArea.value.clientHeight;
        const otherHMargins = 16 + 8;
        const infoH = windowH - hMargins - topBarH - videoAreaH - otherHMargins;
        state.styles.infoHeight = infoH + 'px';
        resizeMap();
      });
    };
    const resizeMap = () => {
      const mapHeight = parseInt(state.styles.infoHeight);
      if (!refExtremeMap.value) { return; }
      refExtremeMap.value.setMapHeight(mapHeight);
      refExtremeMap.value.triggerResize();
    };
    const startPlayingMovie = async() => {
      onResize();
      playFromBeginning(findFirstSeekPoint());
      // currentMovieは再生末尾時点のmgiを持っておらず、そのままだと地図上には50秒時点あたりの経路までしか描画できない.
      // そこで事前に続きの動画があれば取得し、mgiを補足する.
      await appendContinuedMovieMgiToCurrentMovieList();
      updateMap();
    };
    const onCurrentMovieListIdChange = async() => {
      for (const ml of Object.values(state.currentMovieLists)) {
        if (ml.id === state.currentMovieListId) {
          state.currentMovieList = ml;
          invalidateCurrentMovie();
          break;
        }
      }
      if (!state.currentMovieList) {
        return;
      }
      preCalculateMovieVals(state.currentMovieList, props.roadNameDispMap);
      setMoviePlayerControlDispVals(state.currentMovieList);
      if (!state.isInitialized) {
        // 画面が見えてる状態でvideojsの初期化を行わないとvueの処理との兼ね合いで
        // DOMが変なところに挿入されたりする現象があったので、見えていることが
        // 分かっている状態からnextTickしてからinitialize
        nextTick(async() => {
          await initializeViews();
          await startPlayingMovie();
        });
      } else {
        await startPlayingMovie();
      }
      if (refExtremeMap.value) {
        syncGeoItemWithParent(
          state.geoItemsMeta as GeoItemsMeta,
          props.parentGeoItemsMeta,
          refExtremeMap.value,
        );
      }
    };
    const onCurrentMovieListIdChangeBySelect = () => {
      state.showMovieListSelect = false;
      onCurrentMovieListIdChange();
    };
    const initializeViews = () => {
      if (state.isInitialized) { return Promise.resolve(); }
      state.isInitialized = true;
      const p1 = prepareVideoPlayers();
      const p2 = prepareMap();
      return Promise.all([p1, p2]);
    };
    const onVjsError = (vid: string) => {
      state.vjsError.push(vid);
    };
    const videoPlayerHasError = () => {
      return state.vjsError.length > 0;
    };
    const resetVideoPlayers = async() => {
      state.videoControlProperties.reset++;
      state.vjsError = [];
      invalidateCurrentMovie();
    };
    const invalidateCurrentMovie = () => {
      state.currentMovie = null;
      state.currentMovieIdx = -1;
      state.movieFileUrlObj = initMovieFileUrlObjState();
      state.isFirstMovie = false;
      state.isLastMovie = false;
      state.isCurrentMovieReady = false;
      state.currentMgi = null;
      state.currentMgiIdx = -1;
      state.currentMovieCurrentDt = null;
      state.currentLidarMovieCurrentDt = null;
      state.continuedMovies = [];
      state.isMovieEnded = false;
    };
    const onVjsPrepared = (vid: string) => {
      state.vjsPrepared[vid] = true;
    };
    const prepareVideoPlayers = async() => {
      if (refMoviePlayerVideos123.value) {
        refMoviePlayerVideos123.value.forEach(e => {
          e.prepareVideojs();
        });
      }
      if (refMoviePlayerVideoLidar1.value) {
        refMoviePlayerVideoLidar1.value.prepareVideojs();
      }
      const vids = [vid1.value, vid2.value, vid3.value, vidLidar1.value];
      await Promise.all(vids.map(vid => {
        return new Promise<void>((resolve) => {
          const loopFunc = () => {
            if (state.vjsPrepared[vid]) {
              return resolve();
            }
            window.requestTimeout(loopFunc, 100);
          };
          loopFunc();
        });
      }));
    };
    const onVjsTimeUpdate = (rawCurrentTime: number, duration: number) => {
      if (state.isSeeking || state.isTryingToPlayAnotherMovie) { return; }
      if (!state.currentMovie) { return; }
      // update seekbar
      const currentTimeMsec = parseInt((rawCurrentTime * 1000).toString());
      const playOffsetDiff = currentTimeMsec - (state.currentMovie.playStartOffsetMsec || 0);
      state.seek.current = (state.currentMovie.accumMsec || 0) + playOffsetDiff;
      state.currentTime = rawCurrentTime;
      state.currentTimeLidar = state.currentTime + state.lidarMovieOffsetMsec / 1000;

      // 現在の再生位置が実際に撮影された時刻
      if (state.currentMovie.ts) {
        state.currentMovieCurrentDt =
          new Date(state.currentMovie.ts.valueOf() + currentTimeMsec);
        state.currentLidarMovieCurrentDt =
          new Date(state.currentMovie.ts.valueOf() + currentTimeMsec + state.lidarMovieOffsetMsec);
      }
      // mgiの更新
      if (state.currentMgi?.endMsecDiff && state.currentMgi.endMsecDiff <= currentTimeMsec) {
        trySetNextMovieGeoIndex();
      }

      // サーバ側で保持しているdurationはmtxをベースに記録しているが、動画ファイルそのもののdurationは
      // 若干誤差があったりするのでケア.
      if (!refControlArea.value) {
        return;
      }
      if (!refControlArea.value.isPlayBackward) {
        const endMsec = Math.floor(Math.min(duration * 1000, (state.currentMovie.hardEndOffsetMsec || 0)));
        if (endMsec <= currentTimeMsec) {
          if (state.isLastMovie) {
            // 終わり
            pauseUI();
            state.isMovieEnded = true;
          } else {
            // 次の動画を再生
            playNextMovie();
          }
        } else {
          state.isMovieEnded = false;
        }
      } else {
        const startMsec = Math.min(duration * 1000, (state.currentMovie.hardStartOffsetMsec || 0));
        if (startMsec >= currentTimeMsec) {
          if (state.isFirstMovie) {
            // 終わり
            pauseUI();
            state.isMovieEnded = true;
          } else {
            // 次の動画を再生
            playPreviousMovie();
          }
        } else {
          state.isMovieEnded = false;
        }
      }

      if (
        state.analytics.tmpCalcBase === -1 ||
        currentTimeMsec < state.analytics.tmpCalcBase
      ) {
        state.analytics.tmpCalcBase = currentTimeMsec;
      }
      const playedMsec = currentTimeMsec - state.analytics.tmpCalcBase;
      state.analytics.accumulatedPlayMsecs += playedMsec;
      if (state.analytics.accumulatedPlayMsecs >= state.analytics.playMsecSendThres) {
        const seconds = parseInt((state.analytics.accumulatedPlayMsecs / 1000).toString());
        logMoviePlaySeconds(state.playerType, seconds);
        state.analytics.accumulatedPlayMsecs = 0;
      }
      state.analytics.tmpCalcBase = currentTimeMsec;
    };
    const prepareMap = async() => {
      const metaItem = state.geoItemsMeta.map.pin as GeoItemMeta;
      const point = { id: state.mapPinId, lat: 0, lon: 0 };
      if (refExtremeMap.value) {
        refExtremeMap.value.showDataLayer(metaItem, [point]);
      }
    };
    const setMoviePlayerControlDispVals = (movieList: GIMovieList) => {
      // 1つ目のmovieのplayStartOffsetMsecを0位置として扱う
      const firstMovie = movieList.movies[0];
      const firstMovieStartOffsetDiffMsec =
        firstMovie.playStartOffsetMsec === undefined || firstMovie.hardStartOffsetMsec === undefined
          ? 0
          : firstMovie.playStartOffsetMsec - firstMovie.hardStartOffsetMsec;
      // なのでseek.minは0以下の数値となる
      state.seek.min = -firstMovieStartOffsetDiffMsec;
      state.seek.max = (movieList.fullDurationMsec ?? 0) - firstMovieStartOffsetDiffMsec;
      state.seek.current = firstMovie.playStartOffsetMsec || 0;
      state.seek.step = 250; // msec
      state.startTimeDisp = dtFormat(movieList.hardStartTs, 'HH:MM:SS');
      state.endTimeDisp = dtFormat(movieList.hardEndTs, 'HH:MM:SS');
    };
    const playMovie = () => {
      state.videoControlProperties.play++;
    };
    const pauseMovie = () => {
      state.videoControlProperties.pause++;
    };
    const playUI = () => {
      if (!refControlArea.value) { return; }
      refControlArea.value.play();
    };
    const pauseUI = () => {
      if (!refControlArea.value) { return; }
      refControlArea.value.pause();
    };
    const togglePlayPauseUI = () => {
      const playState = getUIPlayState();
      if (playState === 'playing') {
        pauseUI();
      } else {
        playUI();
      }
    };
    const getUIPlayState = () => {
      if (!refControlArea.value) { return; }
      return refControlArea.value.getPlayState();
    };
    const tickMovieBackward = async() => {
      pauseUI();
      // 同一の動画内でのみコマ戻し
      state.videoControlProperties.tickMsec -= 100; // 0.1秒
    };
    const tickMovieForward = async() => {
      pauseUI();
      // 同一の動画内でのみコマ送り
      state.videoControlProperties.tickMsec += 100; // 0.1秒
    };
    const emitSeekChange = (msec: number) => {
      if (!refControlArea.value) { return; }
      refControlArea.value.emitSeekChange(msec);
    };
    const playPreviousMovie = async() => {
      if (state.isTryingToPlayAnotherMovie) { return; }
      if (state.isFirstMovie) { return; }

      state.isTryingToPlayAnotherMovie = true;
      const prevMovieIdx = state.currentMovieIdx - 1;
      const prevMovie = state.currentMovieList?.movies[prevMovieIdx];
      if (!prevMovie) {
        return;
      }
      if (!refControlArea.value) {
        return;
      }
      const offsetMsec = refControlArea.value.isPlayBackward ? (prevMovie.durationMsec || 0) : 0;
      await setCurrentMovie(
        prevMovie,
        prevMovieIdx,
        offsetMsec,
      );
      state.isTryingToPlayAnotherMovie = false;
    };
    const playNextMovie = async() => {
      if (state.isTryingToPlayAnotherMovie) { return; }
      if (state.isLastMovie) { return; }

      state.isTryingToPlayAnotherMovie = true;
      const nextMovieIdx = state.currentMovieIdx + 1;
      const nextMovie = state.currentMovieList?.movies[nextMovieIdx];
      if (!nextMovie) { return; }
      await setCurrentMovie(
        nextMovie,
        nextMovieIdx,
        0,
      );
      state.isTryingToPlayAnotherMovie = false;
    };
    const setPlaySpeed = (val: number) => {
      state.videoControlProperties.playSpeed = val;
    };
    const setVolume = (val: number) => {
      state.videoControlProperties.volume = val;
    };
    const playModeChange = (isPlayBackward: boolean) => {
      state.videoControlProperties.isPlayBackward = isPlayBackward;
      if (!refControlArea.value) {
        return;
      }
      refControlArea.value.setPlayBackward(isPlayBackward);
      if (!refMoviePlayerVideos123.value) {
        return;
      }
      refMoviePlayerVideos123.value.forEach(e => {
        e.changeVjsPlayMode(isPlayBackward);
      });
      if (!refMoviePlayerVideoLidar1.value) {
        return;
      }
      refMoviePlayerVideoLidar1.value.changeVjsPlayMode(isPlayBackward);
    };
    const seekBySeekBar = (offsetMsec: number) => {
      state.seek.current = offsetMsec;
      seekMovie(offsetMsec);
    };
    const seekMovie = async(offsetMsec: number) => {
      if (!state.currentMovieList) {
        return;
      }
      state.isSeeking = true;
      // movieListのmovieについて順番に、offsetMsecのそのmovie内でのoffsetを算出する.
      // そのmovieのplayDuration内にoffsetが収まっていればそれが再生すべきmovie
      // となる. (最後のmovieについて、playEnd < hardEndでplayEnd以降の位置を
      // 指定されていた場合は最後のmovieを再生させたいのでそのようにしてある)
      let targetMovie;
      let targetMovieIdx;
      let targetMoviePlayOffsetMsec = 0;
      for (let i = 0, len = state.currentMovieList.movies.length; i < len; i++) {
        const movie = state.currentMovieList.movies[i];
        targetMovie = movie;
        targetMovieIdx = i;
        targetMoviePlayOffsetMsec = offsetMsec - (targetMovie.accumMsec || 0);
        if (movie.playDurationMsec === undefined || targetMoviePlayOffsetMsec < movie.playDurationMsec) {
          break;
        }
      }
      if (targetMovie === undefined || targetMovieIdx === undefined) {
        state.isSeeking = false;
        return;
      }
      await setCurrentMovie(
        targetMovie,
        targetMovieIdx,
        targetMoviePlayOffsetMsec,
      );
      state.isSeeking = false;
    };
    const setCurrentMovie = async(movie: GIMovie, movieIdx: number, playOffsetMsec: number) => {
      const realOffsetMsec = (movie.playStartOffsetMsec || 0) + playOffsetMsec;
      // 一旦再生を止める
      const playState = getUIPlayState();
      pauseUI();
      state.isCurrentMovieReady = false;

      if (!state.currentMovie || state.currentMovie.id !== movie.id) {
        // 現在再生しているのと異なる動画なので取得する
        state.currentMovie = movie;
        state.currentMovieIdx = movieIdx;
        state.movieFileUrlObj = await getMovieFileUrlObj(state.currentMovie.id);
        state.isFirstMovie = movieIdx === 0;
        state.isLastMovie = !!state.currentMovieList && movieIdx === state.currentMovieList.movies.length - 1;
        state.videoControlProperties.loadMovie++;
        const promises = [];
        // frontのロードを最初にすることで少しだけ体感上のパフォーマンスがよくなるかもしれない.
        // (ほぼ変わらないだろうが.)
        for (const vid of [vid2.value, vid1.value, vid3.value, vidLidar1.value]) {
          state.vjsLoadedData[vid] = false;
          promises.push(new Promise<void>((resolve) => {
            const loopFunc = () => {
              if (state.vjsLoadedData[vid]) {
                return resolve();
              }
              window.requestTimeout(loopFunc, 100);
            };
            loopFunc();
          }));
        }
        console.log(`setCurrentMovie1: update currentMovie. idx=${state.currentMovieIdx}`);
        console.log(state.currentMovie);

        try {
          await Promise.all(promises);
        } catch (e) { return; }
      }
      // ロード中に画面が閉じられた場合のエラーを回避するため
      if (!state.currentMovie) { return; }

      // mgiを計算する
      const movieId = state.currentMovie.id;
      const mgis = state.currentMovie.movie_geo_indices.filter(e => e.movie_id === movieId);
      let targetMgi = null;
      let targetMgiIdx = -1;
      for (let i = 0, len = mgis.length; i < len; i++) {
        targetMgi = mgis[i];
        targetMgiIdx = i;
        if (targetMgi.endMsecDiff && realOffsetMsec < targetMgi.endMsecDiff) {
          break;
        }
      }
      state.currentMgi = targetMgi;
      state.currentMgiIdx = targetMgiIdx;
      updateTitleBarTexts();
      console.log(`setCurrentMovie2: update currentMgi. idx=${state.currentMgiIdx}`);
      console.log(state.currentMgi);

      state.currentTime = realOffsetMsec / 1000;
      state.analytics.tmpCalcBase = realOffsetMsec;

      nextTick(() => {
        // 元々再生中だった場合は、再生を再開
        if (playState === 'playing') {
          playUI();
        }
        state.isCurrentMovieReady = true;
      });
    };
    const trySetNextMovieGeoIndex = () => {
      if (!state.currentMovie) { return; }
      const movieId = state.currentMovie.id;
      const mgis = state.currentMovie.movie_geo_indices.filter(e => e.movie_id === movieId);
      const len = mgis.length;
      if (state.currentMgiIdx < len - 1) {
        state.currentMgi = mgis[state.currentMgiIdx + 1];
        state.currentMgiIdx += 1;
      }
      updateTitleBarTexts();
    };
    const updateTitleBarTexts = () => {
      if (!state.currentMgi) { return; }
      const mgi = state.currentMgi;
      const dateDisp = dtFormat(mgi.ts, 'yyyy/mm/dd HH:MM:SS') + '~';
      const locationDisp = mgi.locationDisp;
      state.titleBarTexts = { dateDisp, locationDisp };
    };
    const updateMapPinPosition = ({ lat, lon }: { lat: string; lon: string}) => {
      const layerMgr = state.geoItemsMeta.map.pin.layerManager as EMPinLayerManager;
      const point = { lat: parseFloat(lat), lon: parseFloat(lon) };
      layerMgr.updatePinPosition(state.mapPinId, point);
    };
    const closeMoviePlayer = () => {
      setAllUIStateToDefault();
      // 裏でダウンロードさせないため、reset
      nextTick(() => resetVideoPlayers());
      // 続き動画をreset
      state.continuedMovies = [];
      emit('close');
    };
    const setAllUIStateToDefault = async() => {
      pauseUI();
      state.showMovieListSelect = false;
      state.mapElemInfo = null;
      setVideoControlPropertiesToDefault();
      if (refControlArea.value) {
        refControlArea.value.setAllUIStateToDefault();
      }
      if (refMetaInfoArea.value) {
        refMetaInfoArea.value.setAllUIStateToDefault();
      }
      setSensorChartUIStateToDefault();
    };
    const setVideoControlPropertiesToDefault = () => {
      state.videoControlProperties = {
        playSpeed: 1.0,
        volume: 0,
        play: 0,
        pause: 0,
        tickMsec: 0,
        reset: 0,
        loadMovie: 0,
        isPlayBackward: false,
      };
    };
    // TODO: MoviePlayerCompareMode改修時に同関数を統合した上、moviePlayerMixin内に移動する。
    const selectUrlForVid = (vid: string) => {
      if (state.movieFileUrlObj) {
        if (vid.startsWith('vid1-')) {
          return state.movieFileUrlObj.left;
        } else if (vid.startsWith('vid2-')) {
          return state.movieFileUrlObj.front;
        } else if (vid.startsWith('vid3-')) {
          return state.movieFileUrlObj.right;
        } else if (vid.startsWith('vidLidar1-')) {
          return state.movieFileUrlObj.lidar1;
        }
      }
      return '';
    };
    const onVjsLoadedData = (vid: string) => {
      state.vjsLoadedData[vid] = true;
    };
    const updateMap = async() => {
      const ml = state.currentMovieList;
      if (!refExtremeMap.value || !ml) {
        return;
      }
      refExtremeMap.value.refreshMovieLayer([ml]);
      refExtremeMap.value.fitToMovieListsExtent([ml]);
    };
    const playFromBeginning = async(seekPoint: number) => {
      if (videoPlayerHasError()) {
        await resetVideoPlayers();
      }

      const playSpeed = state.videoControlProperties.playSpeed;
      await seekMovie(seekPoint);
      if (state.currentMgi) {
        updateMapPinPosition({
          lat: state.currentMgi.lat.toString(),
          lon: state.currentMgi.lon.toString(),
        });
      }
      await setAllUIStateToDefault();
      setPlaySpeed(playSpeed);
      if (refControlArea.value) {
        refControlArea.value.setPlaySpeed(playSpeed);
      }

      playUI();
      logMoviePlay(state.playerType);
    };
    const seekByMapClick = async(data: Location) => {
      state.isSeeking = true;
      if (!refExtremeMap.value || !state.currentMovieList) {
        state.isSeeking = false;
        return;
      }
      const mgi = refExtremeMap.value.getClosestMgi(data);
      if (!mgi) { state.isSeeking = false; return; }

      // seekByTimeTextと同じロジックの方がきれいだが、
      // ルート検索だと適用できないので、これで.
      let targetMovie;
      let targetMovieIdx;
      let targetMoviePlayOffsetMsec = 0;
      for (let i = 0, len = state.currentMovieList.movies.length; i < len; i++) {
        const movie = state.currentMovieList.movies[i];
        if (movie.id === mgi.movie_id) {
          targetMovie = movie;
          targetMovieIdx = i;
          targetMoviePlayOffsetMsec = (mgi.startMsecDiff ?? 0) - (movie.playStartOffsetMsec || 0);
          break;
        }
      }
      if (!targetMovie || targetMovieIdx === undefined) { state.isSeeking = false; return; }
      await setCurrentMovie(
        targetMovie,
        targetMovieIdx,
        targetMoviePlayOffsetMsec,
      );

      updateMapPinPosition({ lat: mgi.lat, lon: mgi.lon });
      state.isSeeking = false;
    };
    const seekByTimeText = async(data: {dt: Date; dateDisp: string; timeDisp: string}) => {
      state.isSeeking = true;
      // movieListの開始と指定された時刻の差に、seek.minのずらし分を考慮
      let tmpOffset = data.dt.getTime() - (state.currentMovieList?.hardStartTs?.getTime() || 0);
      tmpOffset = Math.max(tmpOffset, 0);
      tmpOffset = Math.min(tmpOffset, (state.currentMovieList?.fullDurationMsec || 0));
      const offsetMsec = tmpOffset + state.seek.min;
      await seekMovie(offsetMsec);
      playUI();
      state.isSeeking = false;
    };
    const onMapItemClicked = () => {
      // TODO
    };
    const onMapAllDeselected = () => {
      // TODO
    };
    const onStartDownloadMovieFile = () => {
      state.isDownloadingMovieFile = true;
    };
    const onFinishDownloadMovieFile = () => {
      state.isDownloadingMovieFile = false;
    };
    const onStartDownloadScreenshot = () => {
      state.isDownloadingScreenshot = true;
    };
    const onFinishDownloadScreenshot = () => {
      state.isDownloadingScreenshot = false;
    };
    const getCurrentMovieListSensorData = async() => {
      if (!state.currentMovieList) {
        return;
      }
      const data = await getSensorDataOfMovieList(state.currentMovieList);
      setSensorData(data);
    };
    const setSensorData = (sensorData: SensorData[]) => {
      if (!refSensorChart.value) { return; }
      refSensorChart.value.updateChartData(sensorData);
    };
    const setSensorChartUIStateToDefault = () => {
      setSensorData([]);
      state.showSensorChart = false;
    };
    const openSensorChart = () => {
      state.showSensorChart = true;
      if (refSensorChart.value && !refSensorChart.value.hasSensorData) {
        getCurrentMovieListSensorData();
      }
    };
    const closeSensorChart = () => {
      state.showSensorChart = false;
    };
    const onSensorChartClick = (dt: Date) => {
      if (!dt) { return; }

      dt = new Date(dt);
      const dateDisp = dtFormat(dt, 'yyyy/mm/dd');
      const timeDisp = dtFormat(dt, 'HH:MM:SS');
      seekByTimeText({ dt, dateDisp, timeDisp });
    };
    const startEditMovieTagsOfMovie = (movie: GIMovie) => {
      pauseUI();
      state.movieTagModal.movie = movie;
      state.movieTagModal.tags = movie.tags;
      state.movieTagModal.show = true;
    };
    const startEditMovieTags = async() => {
      if (!state.currentMovie) {
        return;
      }
      startEditMovieTagsOfMovie(state.currentMovie);
    };
    const startCreateMapElem = async() => {
      pauseUI();
      if (!refMoviePlayerVideos123.value) {
        return;
      }
      const promises = refMoviePlayerVideos123.value.map(async(elem) => {
        return elem.getScreenshotBlob();
      });
      const screenshots = await Promise.all(promises);
      if (!state.currentMgi) {
        return;
      }
      const candidateImages: Array<Blob | string> = [];
      screenshots.forEach((screenshot) => {
        if (screenshot) {
          candidateImages.push(screenshot);
        }
      });
      state.mapElemInfo = {
        dataName: 'new',
        data: {
          lat: state.currentMgi.lat,
          lon: state.currentMgi.lon,
          kp_uid: state.currentMgi.kp_uid,
        },
        candidateImages,
      };
    };
    const createMapElem = async(obj: MapElemInfo<Comment>) => {
      if (!props.parentGeoItemsMeta.map[obj.dataName]) {
        console.warn('tryCreateMapElem. Unknown resource type', obj.dataName);
        return;
      }
      if (!state.currentMgi) { return; }

      // 保存ボタン押下時の位置を登録
      obj.data.lat = state.currentMgi.lat;
      obj.data.lon = state.currentMgi.lon;

      const metaItem = props.parentGeoItemsMeta.map[obj.dataName];
      try {
        const resource = await metaItem.manager.createResource(obj.data);
        if (props.parentGeoItemsMeta.show[obj.dataName]) {
          metaItem.layerManager.addLayerItem(resource);
        }
        state.mapElemInfo = null;
        if (refExtremeMap.value) {
          syncGeoItemWithParent(
            state.geoItemsMeta as GeoItemsMeta,
            props.parentGeoItemsMeta,
            refExtremeMap.value,
          );
        }
      } catch (e) {
        state.createMapElemFailed++;
      }
    };
    const shortcutKeyAction = (key: string) => {
      switch (key) {
        case 'space':
          togglePlayPauseUI();
          break;
        case 'left':
          tickMovieBackward();
          break;
        case 'right':
          tickMovieForward();
          break;
        case 'escape':
          if (state.movieTagModal.show) {
            state.movieTagModal.show = false;
          }
          break;
      }
    };
    const switchToCompareMode = () => {
      setAllUIStateToDefault();
      // 同一路線#方向でしぼる
      const mlCurrent = state.currentMovieList;
      const refRoadNameDisp = mlCurrent?.start_kp.road_name_disp;
      const refDirection = mlCurrent?.start_kp.direction;
      const filteredMovieLists = props.movieLists.filter(ml => {
        return refRoadNameDisp === ml.start_kp.road_name_disp &&
          refDirection === ml.start_kp.direction;
      });
      const params: MoviePlayerParams = {
        movieLists: filteredMovieLists,
        movieListId: state.currentMovieListId,
      };
      emit('switch-to-compare-mode', params);
    };
    const openInfraDoctor = () => {
      if (!state.isCurrentMovieReady ||
          !state.currentMovie ||
          !state.currentMovieList ||
          !state.currentMgi) { return; }
      const params = {
        ifdLinkPrefix: ifdLink.value,
        currentMovieList: state.currentMovieList,
        currentMovie: state.currentMovie,
        currentMovieIdx: state.currentMovieIdx,
        currentMgi: state.currentMgi,
        currentMgiIdx: state.currentMgiIdx,
        isFirstMovie: state.isFirstMovie,
        isLastMovie: state.isLastMovie,
      };
      openNewInfraDoctorTab(params);
    };
    const updateLidarMovieOffset = (diffMsec: number) => {
      if (!state.currentMovieCurrentDt) { return; }
      let newOffset = state.lidarMovieOffsetMsec + diffMsec;
      newOffset = Math.max(state.lidarMovieOffsetMsecMin, newOffset);
      newOffset = Math.min(state.lidarMovieOffsetMsecMax, newOffset);
      state.lidarMovieOffsetMsec = newOffset;
      state.currentTimeLidar = state.currentTime + state.lidarMovieOffsetMsec / 1000;
      if (!isCurrentMovieListSearchTypeRoute.value) {
        state.currentLidarMovieCurrentDt =
          new Date(state.currentMovieCurrentDt.valueOf() + state.lidarMovieOffsetMsec);
      }
    };
    const appendContinuedMovieMgiToCurrentMovieList = async() => {
      if (isCurrentMovieListSearchTypeRoute.value) return;
      await tryGetContinuedMovies();
      appendFirstMgisFromContinuedMovieToLastMovie(state.currentMovieList, state.continuedMovies);
    };
    const tryGetContinuedMovies = async() => {
      if (state.continuedMovies.length > 0) return;

      const currentMovies = state.currentMovieList?.movies;
      if (!currentMovies) return;

      const lastMovie = currentMovies[currentMovies.length - 1];
      state.continuedMovies = await searchContinuedMovies(lastMovie);
    };
    const onPlayContinuedMovies = async() => {
      if (state.isTryingToPlayAnotherMovie || !state.currentMovie || !state.currentMovieList) { return; }

      state.isTryingToPlayAnotherMovie = true;
      // 最後の動画では無くなったため、end_offset_msecを無効化する.
      state.currentMovie.end_offset_msec = null;

      // 次の動画をcurrentMovieListに追加することで、同じMovieGroupと同じような操作性を得られ、
      // かつロジックも流用可能になる。
      // (currentMovieListはprops.movieListsから取得しており、
      // concatだとprops.movieListsが更新されないのでpushしておく)
      state.currentMovieList.movies.push(...state.continuedMovies);
      state.continuedMovies = [];
      // 地図上に続きの動画までの線を描画するため、最後の動画からの続きの動画を取得する
      await tryGetContinuedMovies();
      // currentMovieListが更新されたことにより、再計算する。
      state.currentMovieList.isPreCalculated = false;
      preCalculateMovieVals(state.currentMovieList, props.roadNameDispMap);
      setMoviePlayerControlDispVals(state.currentMovieList);
      if (refMetaInfoArea.value) {
        refMetaInfoArea.value.setAllUIStateToDefault();
      }
      setSensorChartUIStateToDefault();

      const nextMovieIdx = state.currentMovieIdx + 1;
      const nextMovie = state.currentMovieList.movies[nextMovieIdx];
      await setCurrentMovie(
        nextMovie,
        nextMovieIdx,
        0,
      );
      state.isTryingToPlayAnotherMovie = false;
      appendFirstMgisFromContinuedMovieToLastMovie(state.currentMovieList, state.continuedMovies);
      updateMap().then(() => {});
      playUI();
    };
    const updateMovieTags = (resultTags: MovieTag[]) => {
      if (!state.movieTagModal.movie) {
        return;
      }
      updateMovieTagsHelper(resultTags, state.movieTagModal.movie);
      state.movieTagModal.show = false;
    };

    // others
    const mapElemIconPath = '/static/img/comment_icon_01.png';

    return {
      ...toRefs(state),
      // refes
      refExtremeMap,
      refControlArea,
      refTopBarArea,
      refVideoArea,
      refMetaInfoArea,
      refSensorChart,
      refMoviePlayerVideos123,
      refMoviePlayerVideoLidar1,
      // computed
      ifdLink,
      vid1,
      vid2,
      vid3,
      vidLidar1,
      videoAspectRatio,
      lidarVideoAspectRatio,
      lidarMovieOffsetMsecDisp,
      currentLidarMovieCurrentDtDisp,
      isCurrentMovieListReady,
      isCurrentMovieListSearchTypeRoute,
      compareModeTooltip,
      visibleGeoItemLayers,
      hasContinuedMovies,
      // methods
      findFirstSeekPoint,
      onCurrentMovieListIdChange,
      onCurrentMovieListIdChangeBySelect,
      initializeViews,
      onVjsError,
      videoPlayerHasError,
      resetVideoPlayers,
      invalidateCurrentMovie,
      onVjsPrepared,
      prepareVideoPlayers,
      onVjsTimeUpdate,
      prepareMap,
      setMoviePlayerControlDispVals,
      playMovie,
      pauseMovie,
      playUI,
      pauseUI,
      togglePlayPauseUI,
      getUIPlayState,
      tickMovieBackward,
      tickMovieForward,
      emitSeekChange,
      playPreviousMovie,
      playNextMovie,
      setPlaySpeed,
      setVolume,
      playModeChange,
      seekBySeekBar,
      seekMovie,
      setCurrentMovie,
      trySetNextMovieGeoIndex,
      updateTitleBarTexts,
      updateMapPinPosition,
      closeMoviePlayer,
      setAllUIStateToDefault,
      setVideoControlPropertiesToDefault,
      selectUrlForVid,
      onVjsLoadedData,
      updateMap,
      playFromBeginning,
      seekByMapClick,
      seekByTimeText,
      onMapItemClicked,
      onMapAllDeselected,
      onStartDownloadMovieFile,
      onFinishDownloadMovieFile,
      onStartDownloadScreenshot,
      onFinishDownloadScreenshot,
      getCurrentMovieListSensorData,
      openSensorChart,
      closeSensorChart,
      onSensorChartClick,
      startEditMovieTags,
      startCreateMapElem,
      createMapElem,
      shortcutKeyAction,
      switchToCompareMode,
      openInfraDoctor,
      updateLidarMovieOffset,
      onPlayContinuedMovies,
      playerControlCurrentTimeDisp,
      updateMovieTags,
      dtFormat,
      // others
      mapElemIconPath,
    };
  },
  components: {
    MoviePlayerControlArea,
    MovieMetaInfoArea,
    MapElemInfoNew,
    MoviePlayerVideo,
    ThreeAxisSensorChart,
  },
});
